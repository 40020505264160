/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2")
    format("woff2");
}
/* CATEGORY CSS HERE */
.order_img{max-width:50px;margin-right: 15px;}
.order_check{margin-top: 20px;}
.strike-off{text-decoration: line-through;margin-right:10px;}
.cat_mainwrap{position: relative;display: inline-block;}
.cat_mainwrap:hover .cat_btnwrap{display: inline-block;}
.cat_btnwrap{width: 100%;display: none;
  position: absolute;
  height: 100%;
  background-color:rgba(0, 0, 0, 0.7);}
  .cat_btnwrap a {color:#ffffff !important;margin:8px 2px;display: inline-block;}
/* Theme level changes */
p{
  margin: 0;
  font-size: 14px;
}

th {
  text-align:left
}
table, th, td {

  border-collapse: collapse;
  text-align: center;
  align-items: center;
  align-content: center;
}


.btn-primary{
  background: #2d48c9;
  border: none;
  outline: none;
  color: #fff;
}

.btn-primary:hover, .btn-primary:active, .btn:active, .btn:first-child:active{
  background: #2596be;
}

.form-control, .form-select{
  font-size: 12px;
  padding:8px;
  border: 1px solid #000;
}

.form-control:focus, .form-select:focus{
border: 1px solid #80df6d;
box-shadow: none;
}

.form-scrollbar{
  border-radius:5px;
  margin-top:30px;
}
.form-scrollbar:focus{
  border:1px solid #80df6d;
}

label {
  display: inline-block;
  margin-bottom: 5px !important;
  font-size: 12px;
  color: #333;
  font-weight: 600;
}

.form-check-input:checked{
  background-color: #26e139;
  border-color: #26e139;
}
.form-check-input:focus{
  box-shadow: none;
}
.mini-text{
  font-size: .7rem;
  color: #333;
}
.h-scroll{
  overflow-y: scroll;
}
.v-scroll{
  overflow-x: scroll;
}

.h-scroll::-webkit-scrollbar, .v-scroll::-webkit-scrollbar{
  display: none;
}
.page-link, .page-link:hover{
  color: #04a91a;
  font-size: .8rem;
  border: none;
}

.page-link:hover, .page-link:focus{
  border: none;
}
.page-link.active{
  color: #fff;
  background: #0a9a03;
}
/* Theme level changes */
.navbar {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #fff;
  border-radius: 10px;
  /* height: 500px; */
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

.nopadding {

  padding: 0 !important;
  margin: 0 !important;

}
.flex-container {
  display: flex;
  justify-content: space-between;
}

.grid-container {
  display: grid;
  grid-template-columns: 5rem 7rem 7rem;

}
.edit-icon-btn{
  background: #0430a9;
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  float: left;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;

}
.edit-rupee-btn{
  background: #0ddb33;
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  float: left;
}
.delete-icon-btn{
  background: red;
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  float: left;
}
* {
  padding: 0;
  text-decoration: none;
}

.sidenav-layout {
  display: flex;
}
main {
  background: #f3f3f3;
  width: 100%;
  min-height: 100vh;
  min-width: 0;
  flex: 1 1 0%;
  transition: all 0.5s;
  padding-top: 65px;
}

.sidebar {
  background: linear-gradient(#2d48c9, #2d48c9);
  color: #fff;
  width: 200px;
  transition: all 0.5s;
  z-index: 999;
  top: 0;
  position: fixed;
  overflow-y: scroll;
  height: 100%;
}

.sidebar::-webkit-scrollbar{
  display: none;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 10px 11px;
}

.logo {
  font-size: 30px;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
  cursor: pointer;
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 20px;
  gap: 5px;
  transition: all 0.5s;
  text-decoration: none;
  margin-bottom: 15px;
}

.link:hover {
  /* color: #fff; */
  transition: all 0.5s;
}
.side-nav-active:hover {
  color: #0ca904 !important;
}
.mnu {
 
  /* padding-right: 10px; */
}

.subMenu {
  background: #0587a7 !important;
  border-radius: 0.375rem;
}

.subMenu > .link {
  font-size: 13px;
  color: #fff;
  font-weight: 500;
}

.side-nav-active {
  background: #f1f5f8; 
  color: #000;
  border-radius: 20px;
 
}

.subMenu > .active {
  background: #26a3db;
  color: #fff;
  border-radius: 20px;
}

.icon,
.link_text {
  font-size: 15px;
  font-weight: 500;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.header-nav {
  background: #d11717;
  position: fixed;
  color: #000;
  z-index: 99;
  width: 100%;
}

.header-nav .nav-link {
  color: #000;
  font-size: 0.8rem;
  padding: 1rem;
}

.nav-pills .show > .nav-link {
  background: #0962d4;
  color: #fff;
  border-radius: 0;
  padding: 1rem;
}

.btn-purple-bg{
  color: #fff !important;
  background-color: #1da12f !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    top: 0;
    left: -96px;
    margin-top: 55px;
  }
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 10px;
  clear: both;
  font-weight: 600;
  /* color: #fff; */
  text-decoration: none;
  white-space: nowrap;
  font-size: 14px;
}
.dropdown-menu {
  /* background: #146578; */
}
.dropdown-toggle::after {
  color: #0446a9;
}
@media (min-width: 1200px) {
  .offset-xl-3 {
    margin-left: 25%;
  }
}
.img-bg {
  background-position: right;
  background-size: auto;
  background-repeat: no-repeat;
}

.bg-primary {
  background-color: #0631b7 !important;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  border: 1px solid #1c3faa;
}
@media (min-width: 767.98px) {
  .mble-dsply {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .sidebar {
    display: none;
  }
  .dsply-desktp {
    display: none;
  }
  .mnu {
  margin-top: 68px;
  position: fixed;
  height: 100%;
  width: 70%;
  background: linear-gradient(#2d48c9, #2d48c9);
  transition: all 0.5s;
  }
  .mble-dsply1 {
    width: 100%;
    border-bottom: 1px solid #e6e7e7;
    margin-bottom: 20px;
    background: #2d48c9;
    position: fixed;
    z-index: 999;
  }
  .mobile-menu-toggle{
    color: #fff !important;
    font-size: 1.5rem;
    padding-bottom: 10px;
    padding-left: 10px;
  }
 
  main {
    margin: 0 !important;
    padding-top: 58px;

  }

/* HTML: <div class="loader"></div> */
.loader {
  width: 150px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader::before {
  border-color: #f03355 #0000;
  animation: inherit; 
  animation-duration: .5s;
  animation-direction: reverse;
}
.loader::after {
  margin: 8px;
}
@keyframes l16 { 
  100%{transform: rotate(1turn)}
}

  /* .dropdown-menu{
    transition: all 0.5s;
  }
  .dropdown-menu.show{
    position: fixed !important;
    width: 70%;
    height: 100%;
    
    transform: none !important;
    z-index: 99;
    margin-top: 82px;
    border-radius: none;
  } */
  label {
    font-size: 14px;
  }

  #table #Row1 th {
    text-align: center;
    background-color: #2d48c9;
    color:#ffffff;
  }
  
  #table #Row2 th {
    background-color: #8bca84;
  }
  

  .lgo {
    height: 40px;
    width: 40px;
    margin-right: -14px;
  }

  /* orders css */

  .arrange-row{
    border-left-width: 1px;
    border-style:solid;
    border-left-color:red;
    padding:20px;
  }
}
