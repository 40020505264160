.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: -60px; */
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(#f8f8fb, #f8f8fb);
  overflow: hidden;
}

.login-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #022c43;
  
    /* background-image:    url('../assets/login_bg.jpg'); */
    background-size:     cover;                      /* <------ */
    background-repeat:   repeat;
    background-position: center center;  
  }

  .login {
    top: 50%;
    bottom: 50%;
    max-width: 350px;
    padding: 25px;
    margin: 50% 17px;
    border-radius: 5px;
  }

  .png-container {
    overflow: hidden;
  }

  .png-container img {  
    filter: drop-shadow(0px 100px 0 #2d48c9);
    transform: translateY(-100px);
  }
  .text-centers {
    text-align: center !important
  }
  .login h3 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: capitalize;
    color: #312f3d;
  }
  .form-group {
    margin-bottom: 1rem;
  }

  
 
  
  .loaderDivCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
  }
  
  .loaderParentDiv {
    width: "100%";
    height: "100%";
    z-index: "999";
    overflow: "scroll";
  }

